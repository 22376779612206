import { createPortal } from "react-dom";
import { useTranslation } from "react-i18next";
import { ReactComponent as Close } from "../../../../assets/svg/x-close.svg";
import { tutorialMobile } from "../../../../constants/tutorial";
import { useTutorial } from "../../../../stores/tutorialStore";
import Button from "../../Buttons/Button/Button";
import Tagline from "../../Tagline/Tagline";

type FilePanelTutorialMobileProps = {
  closeTutorialPanel: () => void;
};

export default function FilePanelTutorialMobile({ closeTutorialPanel }: FilePanelTutorialMobileProps) {
  const { t } = useTranslation();

  const mobileTutorialPageNumber = useTutorial((state) => state.mobileTutorialPageNumber);
  const clearMobileTutorialPageNumber = useTutorial((state) => state.clearMobileTutorialPageNumber);

  const closeTutorial = () => {
    closeTutorialPanel();
    document.body.classList.remove("no-scroll");
    clearMobileTutorialPageNumber();
  };

  return createPortal(
    <div className="absolute top-0 left-0 w-full h-screen bg-transparent-background z-100 flex justify-center items-center md:hidden">
      <div className="w-90 bg-neutral-07 rounded-xl px-4 pt-6 pb-16 flex flex-col gap-8">
        <button
          className="self-end w-10 h-10 bg-pure-white rounded-full flex justify-center items-center"
          onClick={closeTutorial}
        >
          <Close className="w-5 h-5 cursor-pointer stroke-neutral-04" />
        </button>
        <div className="flex gap-2">
          <h1 className="text-[20px]">{t(tutorialMobile[mobileTutorialPageNumber].title)}</h1>
          <Tagline>01</Tagline>
        </div>
        <img src="/images/tutorial/tutorial.png" alt="" className="w-[400px] h-[260px]" />
        <p className="font-light">
          {t(tutorialMobile[mobileTutorialPageNumber].text.text1)}
          <span className="text-electric-purple">{t(tutorialMobile[mobileTutorialPageNumber].text.text2)}</span>{" "}
          {t(tutorialMobile[mobileTutorialPageNumber].text.text3)}
        </p>
        <Button onClick={closeTutorial}>{t("common.skip")}</Button>
      </div>
    </div>,
    document.getElementById("portal") as HTMLElement
  );
}
