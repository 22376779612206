import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { Breakpoints, LocalStorageKeys } from "../../../constants";
import { categoriesOptions } from "../../../constants/categories";
import { authNavigation, noAuthNavigation } from "../../../constants/navigation";
import useResize from "../../../hooks/useResize";
import { routes } from "../../../routes/routes";
import { useGlobalStore } from "../../../stores/globalStore";
import { useUserStore } from "../../../stores/userStore";
import { Offset } from "../../../types/types";
import { scrollWithOffset } from "../../../utils/scrollWithOffset";
import MenuButton from "../Buttons/MenuButton/MenuButton";
import Navbar from "../Navbar/Navbar";

interface MenuProps {
  token: string | null;
  setToken: Dispatch<SetStateAction<string | null>>;
  openMenu: boolean;
  setOpenMenu: Dispatch<SetStateAction<boolean>>;
}

export default function Menu({ token, setToken, openMenu, setOpenMenu }: MenuProps) {
  const { t } = useTranslation();

  const clearUserData = useUserStore((state) => state.clearUser);
  const openSettings = useGlobalStore((state) => state.openSettings);

  const { state: isTablet } = useResize(Breakpoints.tablet);
  const { state: isLaptop } = useResize(Breakpoints.laptop);
  const { state: isDesktop } = useResize(Breakpoints.desktop);

  const setOffset = (offset: Offset) => {
    if (isDesktop) {
      return offset.desktop;
    } else if (isLaptop) {
      return offset.laptop;
    } else if (isTablet) {
      return offset.tablet;
    } else {
      return offset.mobile;
    }
  };

  const signoutUser = () => {
    clearUserData();
    setToken(null);
    localStorage.removeItem(LocalStorageKeys.token);
    setOpenMenu(false);
  };

  const closeMenu = () => {
    setOpenMenu(false);
  };

  const handleClick = (title: string, route: string) => {
    route === routes.home ? signoutUser() : closeMenu();

    if (title === categoriesOptions.settings) {
      openSettings();

      if (!isTablet) {
        document.body.classList.add("no-scroll");
      }

      setOpenMenu(false);
    }
  };

  return (
    <div className="fixed top-0 left-0 w-full h-screen z-10 px-5">
      <div className="w-full h-screen flex justify-center items-center border-l border-pale-lavender/10 border-r">
        {token ? (
          <div className="absolute top-0 left-0 w-full h-21 px-5">
            <div className="relative w-full h-full flex items-end p-2.5">
              <MenuButton onClick={closeMenu} px="px-3" icon="CLOSE" />
              <div className="absolute bottom-0 left-0 w-16 h-[1px] bg-pale-lavender/10"></div>
            </div>
          </div>
        ) : (
          <Navbar token={token} setToken={setToken} openMenu={openMenu} setOpenMenu={setOpenMenu} isInMenu={true} />
        )}
        <ul className="flex flex-col items-center gap-9 text-pure-white">
          {token ? (
            <>
              {authNavigation.map((item) => {
                if (isTablet && !item.showOnTablet) {
                  return null;
                }

                return (
                  <Link key={item.id} to={item.route}>
                    <li className="text-h5 leading-10" onClick={() => handleClick(item.title, item.route)}>
                      {t(item.title)}
                    </li>
                  </Link>
                );
              })}
            </>
          ) : (
            <>
              {noAuthNavigation.map((item) => {
                if (isTablet && !item.showOnTablet) {
                  return null;
                }

                return (
                  <HashLink
                    key={item.id}
                    smooth
                    to={item.route}
                    scroll={(el) => scrollWithOffset(el, setOffset(item.offset))}
                  >
                    <li className="text-h5 leading-10" onClick={closeMenu}>
                      {t(item.title)}
                    </li>
                  </HashLink>
                );
              })}
            </>
          )}
        </ul>
      </div>
      <div className="absolute inset-0 pointer-events-none">
        <div className="absolute top-1/2 left-1/2 w-[51.375rem] aspect-square border border-pale-lavender/10 rounded-full -translate-x-1/2 -translate-y-1/2">
          <div className="absolute top-1/2 left-1/2 w-[36.125rem] aspect-square border border-pale-lavender/10 rounded-full -translate-x-1/2 -translate-y-1/2"></div>
          <div className="absolute top-1/2 left-1/2 w-[23.125rem] aspect-square border border-pale-lavender/10 rounded-full -translate-x-1/2 -translate-y-1/2"></div>
        </div>
        <div className="absolute top-[11.5rem] left-16 w-3 h-3 bg-gradient-to-b from-[#B9AEDF] to-[#1A1A32] rounded-full"></div>
        <div className="absolute top-[14rem] right-3 w-3 h-3 bg-gradient-to-b from-[#B9AEDF] to-[#1A1A32] rounded-full"></div>
        <div className="absolute bottom-[12.5rem] left-12 w-6 h-6 bg-gradient-to-b from-[#88E5BE] to-[#1A1A32] rounded-full"></div>
      </div>
    </div>
  );
}
