export const tutorialSteps = [
  {
    id: 0,
    title: "tutorial.step1.title",
    text: "tutorial.step1.text",
    isBackButton: false,
    isNextButton: true,
    position: "top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2",
  },
  {
    id: 1,
    title: "tutorial.step2.title",
    text: "tutorial.step2.text",
    isBackButton: true,
    isNextButton: true,
    position: "left-84 top-32",
  },
  {
    id: 2,
    title: "tutorial.step3.title",
    text: "tutorial.step3.text",
    isBackButton: true,
    isNextButton: true,
    position: "left-84 top-48",
  },
  {
    id: 3,
    title: "tutorial.step4.title",
    text: "tutorial.step4.text",
    isBackButton: true,
    isNextButton: true,
    position: "left-84 top-60 h-md:top-auto h-md:bottom-0",
  },
  {
    id: 4,
    title: "tutorial.step5.title",
    text: "tutorial.step5.text",
    isBackButton: true,
    isNextButton: false,
    position: "left-84 top-72 h-md:top-auto h-md:bottom-0",
  },
];

export const tutorialMobile = [
  {
    id: 0,
    title: "tutorialMobile.item1.title",
    text: {
      text1: "tutorialMobile.item1.text.text1",
      text2: "tutorialMobile.item1.text.text2",
      text3: "tutorialMobile.item1.text.text3",
    },
    buttonText: "common.skip",
    closeFunction: true,
  },
  {
    id: 1,
    title: "tutorialMobile.item2.title",
    text: {
      text1: "tutorialMobile.item2.text.text1",
      text2: "tutorialMobile.item2.text.text2",
      text3: "tutorialMobile.item2.text.text3",
    },
    buttonText: "common.skip",
    closeFunction: true,
  },
  {
    id: 2,
    title: "tutorialMobile.item3.title",
    text: {
      text1: "tutorialMobile.item3.text.text1",
      text2: "tutorialMobile.item3.text.text2",
      text3: "tutorialMobile.item3.text.text3",
    },
    buttonText: "common.findOutMore",
    closeFunction: false,
  },
  {
    id: 3,
    title: "tutorialMobile.item4.title",
    text: {
      text1: "tutorialMobile.item4.text.text1",
      text2: "tutorialMobile.item4.text.text2",
      text3: "tutorialMobile.item4.text.text3",
    },
    buttonText: "common.skip",
    closeFunction: true,
  },
];
