import { Link } from "react-router-dom";
import { ReactComponent as Collapse } from "../../../assets/svg/collapse-02.svg";
import { ReactComponent as User } from "../../../assets/svg/user-02.svg";
import { sidebarMenu } from "../../../constants/sidebar-menu";
import { routes } from "../../../routes/routes";
import { useActiveTab } from "../../../stores/activeTabStore";
import { useGlobalStore } from "../../../stores/globalStore";

export default function SidebarHidden() {
  const activeTab = useActiveTab((state) => state.activeTab);
  const openSidebar = useGlobalStore((state) => state.openSidebar);

  return (
    <aside className="w-24 h-full px-4 pb-19 flex flex-col justify-between items-center">
      <div className="flex flex-col items-center">
        <Link to={routes.home} className="my-12">
          <img src="/JBLogo.png" alt="JellyBean Logo" />
        </Link>
        <button onClick={openSidebar} className="mx-auto">
          <Collapse />
        </button>
        <ul className="h-sidebar-menu-hidden my-16 flex flex-col gap-6 cursor-pointer overflow-hidden overflow-y-scroll scrollbar-none">
          {sidebarMenu.map((item) => (
            <Link to={item.link} key={item.id}>
              <item.icon className={`${activeTab === item.title && "stroke-electric-purple"}`} />
            </Link>
          ))}
        </ul>
      </div>
      <Link to={routes.myAccount}>
        <div className="w-11 h-11 bg-pure-white rounded-full flex justify-center items-center">
          <User />
        </div>
      </Link>
    </aside>
  );
}
