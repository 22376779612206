import { mountStoreDevtool } from "simple-zustand-devtools";
import { create } from "zustand";

type CookieConsent = {
  cookieConsent: string | null;
  setCookieConsent: (consent: string | null) => void;
  clearTab: () => void;
};

export const useCookieConsent = create<CookieConsent>()((set) => ({
  cookieConsent: null,
  setCookieConsent: (consent) => set(() => ({ cookieConsent: consent })),
  clearTab: () => set(() => ({ cookieConsent: null })),
}));

if (process.env.NODE_ENV === "development") {
  mountStoreDevtool("CookieConsent", useCookieConsent);
}
