import { useGlobalStore } from "../../../stores/globalStore";
import FilePanelClose from "./FilePanelClose";
import FilePanelOpen from "./FilePanelOpen";

type FilePanelProps = {
  token: string | null;
};

export default function FilePanel({ token }: FilePanelProps) {
  const isFilePanelOpen = useGlobalStore((state) => state.isFilePanelOpen);

  return isFilePanelOpen ? <FilePanelOpen token={token} /> : <FilePanelClose />;
}
