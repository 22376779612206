import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import FilePanel from "../components/Elements/FilePanel/FilePanel";
import Menu from "../components/Elements/Menu/Menu";
import Navbar from "../components/Elements/Navbar/Navbar";
import Tutorial from "../components/Elements/Tutorial/Tutorial";
import { dashboardRoutes } from "../constants";
import { useTutorial } from "../stores/tutorialStore";

type NavbarWithMenuProps = {
  token: string | null;
  setToken: Dispatch<SetStateAction<string | null>>;
  openMenu: boolean;
  setOpenMenu: Dispatch<SetStateAction<boolean>>;
};

export default function NavbarWithMenu({ token, setToken, openMenu, setOpenMenu }: NavbarWithMenuProps) {
  const [isDashboard, setIsDashboard] = useState(false);

  const location = useLocation();

  const isTutorialOpen = useTutorial((state) => state.isOpen);

  useEffect(() => {
    dashboardRoutes.includes(location.pathname) ? setIsDashboard(true) : setIsDashboard(false);
  }, [location]);

  return openMenu ? (
    <Menu token={token} setToken={setToken} openMenu={openMenu} setOpenMenu={setOpenMenu} />
  ) : (
    <>
      <Navbar token={token} setToken={setToken} openMenu={openMenu} setOpenMenu={setOpenMenu} />
      <Outlet context={[token, setToken]} />
      {isDashboard && <FilePanel token={token} />}
      {isTutorialOpen && <Tutorial isForMobile={true} />}
    </>
  );
}
