import { Dispatch, SetStateAction } from "react";
import { Breakpoints } from "../../../../constants";
import useResize from "../../../../hooks/useResize";
import { AgentSetting } from "../../../../types/types";
import NewFeaturesAd from "../../../Pages/Dashboard/components/NewFeaturesAd";
import FadingLoader from "./FadingLoader";
import FilePanelHeader from "./FilePanelHeader";
import FilePanelList from "./FilePanelList";

type FilePanelContainerProps = {
  isLoading: boolean;
  showInfo: boolean;
  setShowInfo: Dispatch<SetStateAction<boolean>>;
  setOpenSearch: Dispatch<SetStateAction<boolean>>;
  setSearchResults: Dispatch<SetStateAction<AgentSetting[]>>;
  token: string | null;
};

export default function FilePanelContainer({
  isLoading,
  showInfo,
  setShowInfo,
  setOpenSearch,
  setSearchResults,
  token,
}: FilePanelContainerProps) {
  const { state: isTablet } = useResize(Breakpoints.tablet);

  return (
    <aside className="w-full bg-neutral-07 md:w-90 md:bg-neutral-06 md:border-l md:border-neutral-05 2xl:block">
      <FilePanelHeader setOpenSearch={setOpenSearch} setSearchResults={setSearchResults} />
      {isLoading ? (
        <div className="w-full py-6 px-4">
          <FadingLoader number={10} />
        </div>
      ) : (
        <FilePanelList showInfo={showInfo} token={token} />
      )}
      {showInfo && isTablet && <NewFeaturesAd setShowInfo={setShowInfo} />}
    </aside>
  );
}
