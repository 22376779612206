import { Dispatch, SetStateAction } from "react";
import { useNavigate } from "react-router-dom";
import { LocalStorageKeys } from "../../../../constants";
import { routes } from "../../../../routes/routes";
import { useUserStore } from "../../../../stores/userStore";
import Button from "../Button/Button";

interface LogoutProps {
  title: string;
  setToken: Dispatch<SetStateAction<string | null>>;
  className?: string;
}

export default function Logout({ title, setToken, className }: LogoutProps) {
  const navigate = useNavigate();

  const clearUserData = useUserStore((state) => state.clearUser);

  return (
    <Button
      className={className}
      onClick={() => {
        clearUserData();
        setToken(null);
        localStorage.removeItem(LocalStorageKeys.token);
        navigate(routes.home);
      }}
    >
      {title}
    </Button>
  );
}
