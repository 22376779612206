import { useTranslation } from "react-i18next";
import { ReactComponent as Close } from "../../../../assets/svg/close.svg";
import { Breakpoints } from "../../../../constants";
import { tutorialMobile } from "../../../../constants/tutorial";
import useResize from "../../../../hooks/useResize";
import { useGlobalStore } from "../../../../stores/globalStore";
import { useTutorial } from "../../../../stores/tutorialStore";
import Button from "../../Buttons/Button/Button";
import FilePanelTutorialMobile from "./FilePanelTutorialMobile";

export default function FilePanelTutorial() {
  const { t } = useTranslation();

  const { state: isTablet } = useResize(Breakpoints.tablet);

  const mobileTutorialPageNumber = useTutorial((state) => state.mobileTutorialPageNumber);
  const closeTutorialPanel = useGlobalStore((state) => state.closeTutorialPanel);

  const handleButtonClick = (closeFunction: boolean) => {
    if (closeFunction) {
      closeTutorialPanel();
    } else {
      closeTutorialPanel();
    }
  };

  return (
    <>
      {isTablet ? (
        <aside className="w-90 bg-neutral-06 border-l border-neutral-05">
          <div className="w-full h-18 border-b border-neutral-05 px-10 py-4 flex justify-end items-center">
            <Close className="cursor-pointer" onClick={closeTutorialPanel} />
          </div>
          <div className="w-full p-6 flex flex-col gap-10">
            <h3 className="text-h5">{t(tutorialMobile[mobileTutorialPageNumber].title)}</h3>
            <img src="/images/tutorial/tutorial.png" alt="" className="w-full h-64" />
            <p className="font-light text-pale-lavender">
              {t(tutorialMobile[mobileTutorialPageNumber].text.text1)}
              <span className="text-electric-purple">
                {t(tutorialMobile[mobileTutorialPageNumber].text.text2)}
              </span>{" "}
              {t(tutorialMobile[mobileTutorialPageNumber].text.text3)}
            </p>
            <Button
              className="self-end"
              onClick={() => handleButtonClick(tutorialMobile[mobileTutorialPageNumber].closeFunction)}
            >
              {t(tutorialMobile[mobileTutorialPageNumber].buttonText)}
            </Button>
          </div>
        </aside>
      ) : (
        <FilePanelTutorialMobile closeTutorialPanel={closeTutorialPanel} />
      )}
    </>
  );
}
