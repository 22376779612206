import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as Dots } from "../../../../assets/svg/dots-horizontal.svg";
import { ReactComponent as File } from "../../../../assets/svg/file-05.svg";
import { ReactComponent as Search } from "../../../../assets/svg/search-tooltip.svg";
import { ReactComponent as Trash } from "../../../../assets/svg/trash-03.svg";
import { URL_AGENT_SETTINGS } from "../../../../constants";
import { routes } from "../../../../routes/routes";
import { useAgentSettingsStore } from "../../../../stores/agentSettingsStore";
import { shortenText } from "../../../../utils/shortenText";
import { showErrorToast, showSuccessToast } from "../../Notification/Notification";
import Popup from "../../Popup/Popup";

type FilePanelListElementProps = {
  setting: { id: string; title: string; content: string };
  token: string | null;
  closeSearchContainer?: () => void;
};

export default function FilePanelListElement({ setting, token, closeSearchContainer }: FilePanelListElementProps) {
  const [visible, setVisible] = useState(false);
  const [isOpenPopup, setIsOpenPopup] = useState(false);

  const { t } = useTranslation();

  const location = useLocation();
  const navigate = useNavigate();

  const removeAgentSetting = useAgentSettingsStore((state) => state.removeAgentSetting);

  const maxTextLength = 24;

  const deleteData = async (id: string) => {
    setIsOpenPopup(false);
    closeSearchContainer && closeSearchContainer();

    try {
      const response = await fetch(`${URL_AGENT_SETTINGS}/delete/${id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        removeAgentSetting(id);
        showSuccessToast(t("notification.messages.deleteData"));
        location.pathname !== routes.dashboard && navigate(routes.dashboard);
      } else {
        showErrorToast(t("notification.messages.problemWithDeleteData"));
      }
    } catch (error) {
      showErrorToast(t("notification.messages.problemWithDeleteData"));
    }
  };

  return (
    <>
      <li
        className="relative group/item h-20 p-4 rounded-xl flex gap-3 cursor-pointer md:hover:bg-light-blue"
        onMouseLeave={() => setVisible(false)}
      >
        <File className="w-6 h-6" />
        <div className="max-w-[215px] flex-grow z-1">
          <h3 className="text-sm leading-6 font-semibold lg:text-base">
            {setting.title.length > maxTextLength ? shortenText(setting.title, maxTextLength) : setting.title}
          </h3>
          <p className="font-light text-light-blue-gray">
            {setting.content.length > maxTextLength ? shortenText(setting.content, maxTextLength) : setting.content}
          </p>
        </div>
        <div className="absolute top-1/2 -translate-y-1/2 right-5 z-10">
          <button onClick={() => setVisible((prev) => !prev)}>
            <Dots className="hover:stroke-pure-white" />
          </button>
          {visible && (
            <ul
              onMouseLeave={() => setVisible(false)}
              className="absolute -top-8 -left-72 w-72 h-24 bg-neutral-05 px-2 rounded-xl shadow-lg z-10 flex flex-col justify-center items-center md:bg-neutral-07"
            >
              <li className="w-full h-10 px-2 flex rounded-lg hover:bg-neutral-06">
                <button
                  className="w-full h-full flex items-center gap-3 text-error-red text-sm leading-6 font-semibold"
                  onClick={() => setIsOpenPopup(true)}
                >
                  <Trash />
                  {t("common.deleteData")}
                </button>
              </li>
              <li className="w-full h-10 px-2 flex rounded-lg hover:bg-neutral-06">
                <Link
                  to={routes.dataUpload}
                  state={{ isEdit: true, setting }}
                  onClick={() => closeSearchContainer && closeSearchContainer()}
                  className="w-full h-full flex items-center"
                >
                  <button className="flex items-center gap-3 text-sm leading-6 font-semibold">
                    <Search className="stroke-pure-white md:stroke-[#3C434A]" />
                    {t("myAccountElements.editData")}
                  </button>
                </Link>
              </li>
            </ul>
          )}
        </div>
      </li>
      <Popup
        open={isOpenPopup}
        setOpen={setIsOpenPopup}
        icon={<Trash className="stroke-pure-white w-6 h-6" />}
        title={t("popup.delete.data")}
        text={t("popup.delete.text")}
        type="delete"
        action={() => deleteData(setting.id)}
      />
    </>
  );
}
