import { mountStoreDevtool } from "simple-zustand-devtools";
import { create } from "zustand";
import { AgentSetting } from "../types/types";

type AgentSettingsStore = {
  agentSettings: AgentSetting[];
  setAgentSettings: (agentSettings: AgentSetting[]) => void;
  removeAgentSetting: (id: string) => void;
  updateAgentSetting: (updatedAgentSetting: AgentSetting) => void;
};

export const useAgentSettingsStore = create<AgentSettingsStore>()((set) => ({
  agentSettings: [],
  setAgentSettings: (agentSettings: AgentSetting[]) => set(() => ({ agentSettings: agentSettings })),
  removeAgentSetting: (id: string) =>
    set((state) => ({ agentSettings: state.agentSettings.filter((agentSetting) => agentSetting.id !== id) })),
  updateAgentSetting: (updatedAgentSetting: AgentSetting) =>
    set((state) => ({
      agentSettings: state.agentSettings.map((agentSetting) =>
        agentSetting.id === updatedAgentSetting.id
          ? { ...agentSetting, title: updatedAgentSetting.title, content: updatedAgentSetting.content }
          : agentSetting
      ),
    })),
}));

if (process.env.NODE_ENV === "development") {
  mountStoreDevtool("AgentSettingsStore", useAgentSettingsStore);
}
