import { RotatingLines } from "react-loader-spinner";

export default function Loading() {
  return (
    <div className="w-full h-screen flex justify-center items-center">
      <RotatingLines
        strokeColor="#7f7d8a"
        strokeWidth="2"
        animationDuration="0.75"
        width="64"
        visible={true}
      />
    </div>
  );
}
