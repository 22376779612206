import { Dispatch, SetStateAction } from "react";
import { useGlobalStore } from "../../../stores/globalStore";
import SidebarHidden from "./SidebarHidden";
import SidebarOpen from "./SidebarOpen";

type SidebarProps = {
  setToken: Dispatch<SetStateAction<string | null>>;
};

export default function Sidebar({ setToken }: SidebarProps) {
  const isSidebarOpen = useGlobalStore((state) => state.isSidebarOpen);

  return isSidebarOpen ? <SidebarOpen setToken={setToken} /> : <SidebarHidden />;
}
