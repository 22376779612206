import { useTranslation } from "react-i18next";
import { AgentSetting } from "../../../../types/types";
import FilePanelListElement from "./FilePanelListElement";

type FilePanelSearchListProps = {
  searchResults: AgentSetting[];
  showInfo: boolean;
  closeSearchContainer: () => void;
  token: string | null;
};

export default function FilePanelSearchList({
  searchResults,
  showInfo,
  closeSearchContainer,
  token,
}: FilePanelSearchListProps) {
  const { t } = useTranslation();

  return (
    <ul
      className={`relative w-full overflow-auto scrollbar-none py-6 px-4 flex flex-col gap-3 z-1 ${
        showInfo ? "lg:h-new-feature" : "lg:h-chat-history"
      }`}
    >
      {searchResults.length > 0 ? (
        searchResults.map((setting) => (
          <FilePanelListElement
            key={setting.id}
            setting={setting}
            token={token}
            closeSearchContainer={closeSearchContainer}
          />
        ))
      ) : (
        <p className="text-base leading-6 text-slate-gray pl-1">{t("dataUpload.noData")}</p>
      )}
    </ul>
  );
}
