import { useEffect, useState } from "react";

export default function useResize(breakpoint: number) {
  const [state, setState] = useState(false);

  const handleResize = () => {
    setState(window.innerWidth >= breakpoint);
  };

  useEffect(() => {
    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return { state };
}
