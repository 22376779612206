import { mountStoreDevtool } from "simple-zustand-devtools";
import { create } from "zustand";

type ActiveTab = {
  activeTab: string;
  changeTab: (newTab: string) => void;
  clearTab: () => void;
};

const initialValue = { activeTab: "" };

export const useActiveTab = create<ActiveTab>()((set) => ({
  activeTab: initialValue.activeTab,
  changeTab: (newTab) => set((state) => ({ activeTab: newTab })),
  clearTab: () => set((state) => ({ activeTab: initialValue.activeTab })),
}));

if (process.env.NODE_ENV === "development") {
  mountStoreDevtool("ActiveTabStore", useActiveTab);
}
