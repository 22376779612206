import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ReactComponent as Collapse } from "../../../assets/svg/collapse.svg";
import { ReactComponent as User } from "../../../assets/svg/user-02.svg";
import { Breakpoints } from "../../../constants";
import { sidebarMenu } from "../../../constants/sidebar-menu";
import useResize from "../../../hooks/useResize";
import { routes } from "../../../routes/routes";
import { useActiveTab } from "../../../stores/activeTabStore";
import { useGlobalStore } from "../../../stores/globalStore";
import { useTutorial } from "../../../stores/tutorialStore";
import { useUserStore } from "../../../stores/userStore";
import Logout from "../Buttons/Logout/Logout";

type SidebarOpenProps = {
  setToken: Dispatch<SetStateAction<string | null>>;
};

export default function SidebarOpen({ setToken }: SidebarOpenProps) {
  const { t } = useTranslation();

  const activeTab = useActiveTab((state) => state.activeTab);
  const user = useUserStore((state) => state.user);
  const closeSidebar = useGlobalStore((state) => state.closeSidebar);
  const currentStep = useTutorial((state) => state.currentStep);
  const isTutorialOpen = useTutorial((state) => state.isOpen);
  const openSettings = useGlobalStore((state) => state.openSettings);

  const { state: isTablet } = useResize(Breakpoints.tablet);

  const launchSettings = () => {
    openSettings();

    if (!isTablet) {
      document.body.classList.add("no-scroll");
    }
  };

  return (
    <aside className="w-80 h-full px-4 pb-10 flex flex-col justify-between">
      <div>
        <div className="w-full h-32 flex justify-between items-center pl-2">
          <Link to={routes.home} className="flex items-center gap-2">
            <img src="/JBLogo.png" alt="JellyBean Logo" />
            <h2 className="text-28 font-bold leading-7">
              JellyBean <span className="absolute text-xs font-light">&trade;</span>
            </h2>
          </Link>
          <button onClick={closeSidebar} className="pr-2">
            <Collapse />
          </button>
        </div>
        <ul className="relative w-full h-sidebar-menu py-4 cursor-pointer overflow-hidden overflow-y-scroll scrollbar-none">
          {sidebarMenu.map((item) => (
            <li key={item.id}>
              <Link
                to={item.link}
                state={item.id === 1 && { isEdit: false, setting: {} }}
                className={`w-full h-12 px-5 rounded-lg flex items-center gap-6 hover:bg-pure-white/10 ${
                  activeTab === item.title && !isTutorialOpen
                    ? "bg-gradient-to-l from-[#323337] to-[#464F6F80] text-pure-white"
                    : "text-light-blue-gray"
                } ${
                  currentStep - 1 === item.id &&
                  isTutorialOpen &&
                  "relative md:bg-pure-white/10 md:z-105 md:pointer-events-none"
                }`}
                onClick={() => item.id === 4 && launchSettings()}
              >
                <item.icon />
                <h3 className="text-lg font-semibold leading-6">{t(item.title)}</h3>
              </Link>
            </li>
          ))}
        </ul>
      </div>
      <div className="w-full h-36 bg-neutral-06 rounded-xl p-[10px] flex flex-col">
        <div className="flex gap-4 px-[10px] pt-[10px] pb-[18px]">
          <div className="w-11 h-11 bg-pure-white rounded-full flex justify-center items-center">
            <User />
          </div>
          <div className="flex-1 overflow-hidden flex flex-col">
            <Link to={routes.myAccount}>
              <h4 className={`font-semibold leading-6 text-pure-white hover:underline hover:underline-offset-4`}>
                {user.username}
              </h4>
            </Link>
            <p className="text-xs font-semibold leading-6 text-light-blue-gray">{user.email}</p>
          </div>
        </div>
        <Logout title={t("common.signOut")} setToken={setToken} />
      </div>
    </aside>
  );
}
