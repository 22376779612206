import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { routes } from "../../../routes/routes";
import { showErrorToast, showSuccessToast } from "../../Elements/Notification/Notification";
import { useTranslation } from "react-i18next";

export default function ConfirmEmail() {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const email = queryParams!.get('email');
    const token = queryParams!.get('token');

    const fetchData = async () => {
      const response = await fetch(`${process.env.REACT_APP_BASE_API_URL}/api/user/confirm-email`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          "Email": email,
          "Token": token
        })
      });

      if (response.ok) {
        showSuccessToast(t("notification.messages.emailConfirmed"));
        navigate(routes.login)
      } else {
        if (response.statusText) {
          showErrorToast(t("notification.messages.problemDuringEmailConfirmation"));
        }
      }
    } 
  
    fetchData().catch((error) => {
        showErrorToast(t("notification.messages.generalError"));
    })
  }, []);

  return (<></>);
}
