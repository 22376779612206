import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { svgs } from "./svgs";

const WHITE = "#FFFFFF";
const DARK = "#0E0C15";

type ButtonColor = "white" | "dark" | undefined;

type ButtonProps = {
  className?: string;
  href?: string;
  onClick?: () => void;
  children: React.ReactNode;
  px?: string;
  type?: "button" | "submit" | "reset";
  color?: ButtonColor;
  disabled?: boolean;
  redBorder?: boolean;
};

export default function Button({
  className,
  href,
  onClick,
  children,
  px,
  type,
  color,
  disabled = false,
  redBorder = false,
}: ButtonProps) {
  const [buttonColor, setButtonColor] = useState<string | undefined>(undefined);

  const classes = `relative z-50 text-button font-source-code-pro uppercase tracking-wider relative inline-flex items-center justify-center h-11 ${
    px || "px-7"
  } ${
    redBorder ? "text-error-red hover:text-error-red" : color === "white" ? "text-midnight-blue" : "text-pure-white"
  } transition-colors hover:text-electric-purple ${className || ""}`;

  const spanClasses = `relative z-10`;

  useEffect(() => {
    if (color === "dark") {
      setButtonColor(DARK);
    } else if (color === "white") {
      setButtonColor(WHITE);
    }
  }, []);

  return href ? (
    <Link to={href} className={classes}>
      <span className={spanClasses}>{children}</span>
      {svgs(buttonColor, redBorder)}
    </Link>
  ) : (
    <button className={classes} onClick={onClick} type={type} disabled={disabled}>
      <span className={spanClasses}>{children}</span>
      {svgs(buttonColor, redBorder)}
    </button>
  );
}
