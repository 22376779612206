import { Dispatch, ReactNode, SetStateAction } from "react";
import { createPortal } from "react-dom";
import { useTranslation } from "react-i18next";

import { ReactComponent as Close } from "../../../assets/svg/x-close.svg";
import Button from "../Buttons/Button/Button";

type PopupType = "done" | "delete";

type PopupProps<T extends (...args: any[]) => void> = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  icon: ReactNode;
  title: string;
  text: string;
  type?: PopupType;
  action: T;
};

export default function Popup<T extends (...args: any[]) => void>({
  open,
  setOpen,
  icon,
  title,
  text,
  type,
  action,
}: PopupProps<T>) {
  const { t } = useTranslation();

  const closePopup = () => {
    setOpen(false);
  };

  return createPortal(
    <>
      {open && (
        <div className="fixed top-0 left-0 w-full h-screen bg-black/30 backdrop-blur-xs z-100 flex justify-center items-center">
          <div className="w-80 bg-pure-white rounded-xl p-6 transition-opacity">
            <Close className="stroke-black mb-2 ml-auto cursor-pointer" onClick={closePopup} />
            <div
              className={`w-11 h-11 bg-error-red rounded-full flex justify-center items-center mb-6 mx-auto ${
                type === "delete" ? "bg-error-red" : "bg-light-green"
              }`}
            >
              {icon}
            </div>
            <div className="flex flex-col items-center gap-4">
              <h1 className={`leading-6 font-semibold text-black ${type === "delete" ? "text-xl" : "text-2xl"}`}>
                {title}
              </h1>
              <p className="font-light text-[#3C3C3C]/75">{text}</p>
              <Button color="dark" className="w-full" onClick={action}>
                {t("common.confirm")}
              </Button>
            </div>
          </div>
        </div>
      )}
    </>,
    document.getElementById("portal") as HTMLElement
  );
}
