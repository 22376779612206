import { mountStoreDevtool } from "simple-zustand-devtools";
import { create } from "zustand";

type Tutorial = {
  isOpen: boolean;
  openTutorial: () => void;
  closeTutorial: () => void;
  currentStep: number;
  increaseCurrentStep: () => void;
  decreaseCurrentStep: () => void;
  setCurrentStep: (stepNumber: number) => void;
  clearCurrentStep: () => void;
  mobileTutorialPageNumber: number;
  setMobileTutorialPageNumber: (pageNumber: number) => void;
  clearMobileTutorialPageNumber: () => void;
};

export const useTutorial = create<Tutorial>()((set) => ({
  isOpen: false,
  openTutorial: () => set(() => ({ isOpen: true })),
  closeTutorial: () => set(() => ({ isOpen: false })),
  currentStep: 0,
  increaseCurrentStep: () => set((state) => ({ currentStep: state.currentStep + 1 })),
  decreaseCurrentStep: () => set((state) => ({ currentStep: state.currentStep - 1 })),
  setCurrentStep: (stepNumber) => set((state) => ({ currentStep: stepNumber })),
  clearCurrentStep: () => set(() => ({ currentStep: 0 })),
  mobileTutorialPageNumber: 0,
  setMobileTutorialPageNumber: (pageNumber: number) => set(() => ({ mobileTutorialPageNumber: pageNumber })),
  clearMobileTutorialPageNumber: () => set(() => ({ mobileTutorialPageNumber: 0 })),
}));

if (process.env.NODE_ENV === "development") {
  mountStoreDevtool("TutorialStore", useTutorial);
}
