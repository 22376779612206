import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as ArrowDown } from "../../../../assets/svg/arrow-narrow-down.svg";
import { Breakpoints } from "../../../../constants";
import useResize from "../../../../hooks/useResize";
import { useAgentSettingsStore } from "../../../../stores/agentSettingsStore";
import FilePanelListElement from "./FilePanelListElement";

type FilesListProps = {
  showInfo: boolean;
  token: string | null;
};

export default function FilePanelList({ showInfo, token }: FilesListProps) {
  const { t } = useTranslation();

  const itemsAmount = 5;

  const [items, setItems] = useState(itemsAmount);

  const agentSettings = useAgentSettingsStore((state) => state.agentSettings);

  const { state: isTablet } = useResize(Breakpoints.tablet);

  return (
    <>
      <ul
        className={`relative w-full overflow-y-scroll scrollbar-none py-6 px-4 flex flex-col gap-3 z-1 ${
          showInfo ? "h-new-feature" : "h-chat-history"
        }`}
      >
        {agentSettings.length > 0 ? (
          agentSettings
            .map((setting) => <FilePanelListElement key={setting.id} setting={setting} token={token} />)
            .slice(0, isTablet ? undefined : items)
        ) : (
          <p className="text-xs leading-4 font-space-grotesk text-slate-gray uppercase text-center">
            {t("dataUpload.noData")}
          </p>
        )}
      </ul>
      {items < agentSettings.length && (
        <div className="w-full flex justify-center md:hidden">
          <button
            onClick={() => setItems((prev) => prev + itemsAmount)}
            className="bg-neutral-06 border border-neutral-05 text-button mb-4 px-4 py-2 rounded-lg shadow-lg flex items-center gap-2 lg:hidden"
          >
            {t("common.loadMore")}
            <ArrowDown className="w-4 h-4" />
          </button>
        </div>
      )}
    </>
  );
}
