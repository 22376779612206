import { useNavigate } from "react-router-dom";
import { LocalStorageKeys } from "../../../constants";
import { routes } from "../../../routes/routes";
import { useTutorial } from "../../../stores/tutorialStore";
import TutorialCard from "./components/TutorialCard";
import TutorialCardMobile from "./components/TutorialCardMobile";

type TestProps = {
  isForMobile: boolean;
};

interface StepMap {
  [key: number]: string;
}

export const stepMap: StepMap = {
  0: routes.dashboard,
  1: routes.dashboard,
  2: routes.dataUpload,
  3: routes.integration,
  4: routes.playground,
};

export default function Tutorial({ isForMobile }: TestProps) {
  const navigate = useNavigate();

  const { currentStep, increaseCurrentStep, decreaseCurrentStep, closeTutorial, clearCurrentStep } = useTutorial();

  const skipTutorial = () => {
    closeTutorial();
    clearCurrentStep();
    localStorage.setItem(LocalStorageKeys.tutorial, "false");
    localStorage.removeItem(LocalStorageKeys.tutorialCurrentStep);
    document.body.classList.remove("no-scroll");
  };

  const stepBack = () => {
    if (isForMobile) {
      const targetRoute = stepMap[currentStep - 1];

      if (targetRoute) {
        navigate(targetRoute);
      }
    }

    decreaseCurrentStep();
    localStorage.setItem(LocalStorageKeys.tutorialCurrentStep, (currentStep - 1).toString());
  };

  const nextStep = () => {
    if (isForMobile) {
      const targetRoute = stepMap[currentStep + 1];

      if (targetRoute) {
        navigate(targetRoute);
      }
    }

    increaseCurrentStep();
    localStorage.setItem(LocalStorageKeys.tutorialCurrentStep, (currentStep + 1).toString());
  };

  const finishTutorial = () => {
    closeTutorial();
    clearCurrentStep();
    localStorage.setItem(LocalStorageKeys.tutorial, "false");
    localStorage.removeItem(LocalStorageKeys.tutorialCurrentStep);

    if (isForMobile) {
      navigate(routes.dashboard);
      document.body.classList.remove("no-scroll");
    }
  };

  const exitTutorial = () => {
    closeTutorial();
    localStorage.setItem(LocalStorageKeys.tutorial, "false");
    document.body.classList.remove("no-scroll");

    if (isForMobile) {
      navigate(routes.dashboard);
    }
  };

  return (
    <>
      {isForMobile ? (
        <TutorialCardMobile
          skipTutorial={skipTutorial}
          stepBack={stepBack}
          nextStep={nextStep}
          finishTutorial={finishTutorial}
          exitTutorial={exitTutorial}
        />
      ) : (
        <TutorialCard
          skipTutorial={skipTutorial}
          stepBack={stepBack}
          nextStep={nextStep}
          finishTutorial={finishTutorial}
          exitTutorial={exitTutorial}
        />
      )}
    </>
  );
}
