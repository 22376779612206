import { routes } from "../routes/routes";

export const noAuthNavigation = [
  {
    id: "0",
    title: `noAuthNavigation.item1.title`,
    route: `${routes.features}`,
    offset: {
      mobile: 40,
      tablet: 30,
      laptop: 200,
      desktop: 160,
    },
    showOnTablet: true,
    displayInRow: true,
    showOnBottom: false,
  },
  {
    id: "1",
    title: `noAuthNavigation.item2.title`,
    route: `${routes.pricing}`,
    offset: {
      mobile: 190,
      tablet: 120,
      laptop: 480,
      desktop: 500,
    },
    showOnTablet: true,
    displayInRow: true,
    showOnBottom: false,
  },
  {
    id: "2",
    title: `noAuthNavigation.item3.title`,
    route: `${routes.howToUse}`,
    offset: {
      mobile: 220,
      tablet: 120,
      laptop: -40,
      desktop: -20,
    },
    showOnTablet: true,
    displayInRow: true,
    showOnBottom: false,
  },
  {
    id: "3",
    title: `noAuthNavigation.item4.title`,
    route: `${routes.roadmap}`,
    offset: {
      mobile: 220,
      tablet: 120,
      laptop: 40,
      desktop: 40,
    },
    showOnTablet: true,
    displayInRow: true,
    showOnBottom: false,
  },
  {
    id: "4",
    title: `common.language`,
    route: `${routes.translation}`,
    offset: {
      mobile: 0,
      tablet: 0,
      laptop: 0,
      desktop: 0,
    },
    showOnTablet: true,
    displayInRow: false,
    showOnBottom: false,
  },
  {
    id: "5",
    title: `noAuthNavigation.item5.title`,
    route: `${routes.login}`,
    offset: {
      mobile: 0,
      tablet: 0,
      laptop: 0,
      desktop: 0,
    },
    showOnTablet: true,
    displayInRow: false,
    showOnBottom: false,
  },
];

export const authNavigation = [
  {
    id: "1",
    title: `authNavigation.item1.title`,
    route: `${routes.dashboard}`,
    showOnTablet: true,
    displayInRow: true,
  },
  {
    id: "2",
    title: `authNavigation.item2.title`,
    route: `${routes.dataUpload}`,
    showOnTablet: true,
    displayInRow: true,
  },
  {
    id: "3",
    title: `authNavigation.item3.title`,
    route: `${routes.integration}`,
    showOnTablet: true,
    displayInRow: true,
  },
  {
    id: "4",
    title: `authNavigation.item4.title`,
    route: `${routes.playground}`,
    showOnTablet: true,
    displayInRow: true,
  },
  {
    id: "5",
    title: `authNavigation.item5.title`,
    route: `${routes.myAccount}`,
    showOnTablet: true,
    displayInRow: true,
  },
  {
    id: "6",
    title: `common.language`,
    route: `${routes.translation}`,
    showOnTablet: true,
    displayInRow: false,
  },
  {
    id: "7",
    title: `common.signOut`,
    route: `${routes.home}`,
    showOnTablet: true,
    displayInRow: false,
  },
];

export const footerNavigation = [
  {
    id: "0",
    title: `noAuthNavigation.item2.title`,
    route: `${routes.subPricing}`,
  },
  {
    id: "1",
    title: `noAuthNavigation.item4.title`,
    route: `${routes.subRoadmap}`,
  },
  {
    id: "2",
    title: `noAuthNavigation.item6.title`,
    route: `${routes.policy}`,
  },
  {
    id: "3",
    title: `noAuthNavigation.item7.title`,
    route: `${routes.terms}`,
  },
];
