import { Dispatch, SetStateAction, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import FilePanel from "../components/Elements/FilePanel/FilePanel";
import Sidebar from "../components/Elements/Sidebar/Sidebar";
import Tutorial from "../components/Elements/Tutorial/Tutorial";
import { Breakpoints, LocalStorageKeys } from "../constants";
import useResize from "../hooks/useResize";
import { routes } from "../routes/routes";
import { useGlobalStore } from "../stores/globalStore";
import { useTutorial } from "../stores/tutorialStore";
import NavbarWithMenu from "./NavbarWithMenu";

type NavigationSwitcherProps = {
  token: string | null;
  setToken: Dispatch<SetStateAction<string | null>>;
  openMenu: boolean;
  setOpenMenu: Dispatch<SetStateAction<boolean>>;
};

export default function NavigationSwitcher({ token, setToken, openMenu, setOpenMenu }: NavigationSwitcherProps) {
  const navigate = useNavigate();

  const isTutorialOpen = useTutorial((state) => state.isOpen);
  const currentStep = useTutorial((state) => state.currentStep);
  const openSidebar = useGlobalStore((state) => state.openSidebar);
  const closeSidebar = useGlobalStore((state) => state.closeSidebar);
  const openFilePanel = useGlobalStore((state) => state.openFilePanel);
  const closeFilePanel = useGlobalStore((state) => state.closeFilePanel);

  const { state: isTablet } = useResize(Breakpoints.tablet);
  const { state: isDesktop } = useResize(Breakpoints.desktop);

  useEffect(() => {
    if (isDesktop) {
      openSidebar();
      openFilePanel();
    } else if (isTablet) {
      closeSidebar();
      closeFilePanel();
    } else if (!isTablet) {
      openFilePanel();
    }
  }, [isTablet, isDesktop]);

  useEffect(() => {
    const tutorial = localStorage.getItem(LocalStorageKeys.tutorial);

    !isTablet && currentStep === 0 && tutorial === "true" && navigate(routes.dashboard);
  }, []);

  return isTablet ? (
    <>
      <div className="w-full h-screen bg-neutral-07 flex">
        <Sidebar setToken={setToken} />
        <div className="relative flex-1 bg-neutral-06 rounded-l-[20px]">
          <Outlet context={[token, setToken]} />
        </div>
        <FilePanel token={token} />
      </div>
      {isTutorialOpen && <Tutorial isForMobile={false} />}
    </>
  ) : (
    <NavbarWithMenu token={token} setToken={setToken} openMenu={openMenu} setOpenMenu={setOpenMenu} />
  );
}
