import { svgs } from "./svgs";

type Icon = "OPEN" | "CLOSE";

type MenuButtonProps = {
  onClick?: () => void;
  px?: string;
  icon?: Icon;
};

export default function MenuButton({ onClick, px, icon }: MenuButtonProps) {
  const classes = `text-button font-source-code-pro uppercase tracking-wider relative inline-flex items-center justify-center h-11 ${
    px || "px-7"
  } `;

  const spanClasses = `relative z-10`;

  return (
    <>
      <button className={classes} onClick={onClick}>
        <span className={spanClasses}>
          <svg className="overflow-visible" width="20" height="12" viewBox="0 0 20 12">
            <rect
              className="transition-all origin-center"
              y={icon === "CLOSE" ? "5" : "0"}
              width="20"
              height="2"
              rx="1"
              fill="white"
              transform={icon === "CLOSE" ? "rotate(45)" : "rotate(0)"}
            />
            <rect
              className="transition-all origin-center"
              y={icon === "CLOSE" ? "5" : "10"}
              width="20"
              height="2"
              rx="1"
              fill="white"
              transform={icon === "CLOSE" ? "rotate(-45)" : "rotate(0)"}
            />
          </svg>
        </span>
        {svgs()}
      </button>
      <svg className="block" width={0} height={0}>
        <defs>
          <linearGradient id="btn-left" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor="#89F9E8" />
            <stop offset="100%" stopColor="#FACB7B" />
          </linearGradient>
          <linearGradient id="btn-top" x1="100%" x2="0%" y1="50%" y2="50%">
            <stop offset="0%" stopColor="#D87CEE" />
            <stop offset="100%" stopColor="#FACB7B" />
          </linearGradient>
          <linearGradient id="btn-bottom" x1="100%" x2="0%" y1="50%" y2="50%">
            <stop offset="0%" stopColor="#9099FC" />
            <stop offset="100%" stopColor="#89F9E8" />
          </linearGradient>
          <linearGradient id="btn-right" x1="14.635%" x2="14.635%" y1="0%" y2="100%">
            <stop offset="0%" stopColor="#9099FC" />
            <stop offset="100%" stopColor="#D87CEE" />
          </linearGradient>
        </defs>
      </svg>
    </>
  );
}
