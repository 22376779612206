import { mountStoreDevtool } from "simple-zustand-devtools";
import { create } from "zustand";

type UserStore = {
  user: {
    id: string;
    username: string;
    email: string;
  };
  setUser: (id: string, username: string, email: string) => void;
  clearUser: () => void;
};

const initialValue = { id: "", username: "", email: "" };

export const useUserStore = create<UserStore>()((set) => ({
  user: initialValue,
  setUser: (id, username, email) => set((state) => ({ user: { id, username, email } })),
  clearUser: () => set({ user: initialValue }),
}));

if (process.env.NODE_ENV === "development") {
  mountStoreDevtool("UserStore", useUserStore);
}
