import { Dispatch, SetStateAction, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { stepMap } from "../components/Elements/Tutorial/Tutorial";
import { Breakpoints, LocalStorageKeys } from "../constants";
import useResize from "../hooks/useResize";
import { routes } from "../routes/routes";
import { useTutorial } from "../stores/tutorialStore";
import NavigationSwitcher from "./NavigationSwitcher";

type AuthorizationWrapperProps = {
  token: string | null;
  setToken: Dispatch<SetStateAction<string | null>>;
  openMenu: boolean;
  setOpenMenu: Dispatch<SetStateAction<boolean>>;
};

export default function AuthorizationWrapper({ token, setToken, openMenu, setOpenMenu }: AuthorizationWrapperProps) {
  const navigate = useNavigate();

  const openTutorial = useTutorial((state) => state.openTutorial);
  const setCurrentStep = useTutorial((state) => state.setCurrentStep);
  const currentStep = useTutorial((state) => state.currentStep);

  const { state: isTablet } = useResize(Breakpoints.tablet);

  useEffect(() => {
    const token = localStorage.getItem(LocalStorageKeys.token);

    const authorization = async () => {
      const response = await fetch(`${process.env.REACT_APP_AUTH_URL}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      response.ok ? setToken(token) : navigate(routes.login);
    };

    token ? authorization() : navigate(routes.login);

    const tutorial = localStorage.getItem(LocalStorageKeys.tutorial);
    const tutorialCurrentStep = localStorage.getItem(LocalStorageKeys.tutorialCurrentStep);

    if (tutorial === null) {
      openTutorial();
      localStorage.setItem(LocalStorageKeys.tutorial, "true");
      localStorage.setItem(LocalStorageKeys.tutorialCurrentStep, "0");
    } else {
      if (tutorial === "true") {
        openTutorial();
        if (tutorialCurrentStep !== null) {
          setCurrentStep(parseInt(tutorialCurrentStep));

          if (!isTablet) {
            const targetRoute = stepMap[currentStep];

            if (targetRoute) {
              navigate(targetRoute);
            }
          }
        } else localStorage.setItem(LocalStorageKeys.tutorialCurrentStep, "0");
      } else {
        tutorialCurrentStep !== null && setCurrentStep(parseInt(tutorialCurrentStep));
      }
    }
  }, [navigate]);

  return <NavigationSwitcher token={token} setToken={setToken} openMenu={openMenu} setOpenMenu={setOpenMenu} />;
}
