import { ReactComponent as Dashboard } from "../assets/svg/sidebar-menu/dashboard.svg";
import { ReactComponent as DataUploaded } from "../assets/svg/sidebar-menu/data-uploaded.svg";
import { ReactComponent as Playground } from "../assets/svg/sidebar-menu/message-chat-square.svg";
import { ReactComponent as Integration } from "../assets/svg/sidebar-menu/puzzle-piece.svg";
import { routes } from "../routes/routes";

export const sidebar = {
  dashboard: "sidebarMenu.dashboard",
  dataUpload: "sidebarMenu.dataUpload",
  integration: "sidebarMenu.integration",
  playground: "sidebarMenu.playground",
};

export const sidebarMenu = [
  {
    id: 0,
    title: "sidebarMenu.dashboard",
    icon: Dashboard,
    link: routes.dashboard,
  },
  {
    id: 1,
    title: "sidebarMenu.dataUpload",
    icon: DataUploaded,
    link: routes.dataUpload,
  },
  {
    id: 2,
    title: "sidebarMenu.integration",
    icon: Integration,
    link: routes.integration,
  },
  {
    id: 3,
    title: "sidebarMenu.playground",
    icon: Playground,
    link: routes.playground,
  },
];
