import { ChangeEvent, Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as Close } from "../../../../assets/svg/x-close.svg";
import { Breakpoints } from "../../../../constants";
import useResize from "../../../../hooks/useResize";
import { useAgentSettingsStore } from "../../../../stores/agentSettingsStore";
import { AgentSetting } from "../../../../types/types";
import NewFeaturesAd from "../../../Pages/Dashboard/components/NewFeaturesAd";
import FilePanelSearchList from "./FilePanelSearchList";

type FilePanelSearchProps = {
  openSearch: boolean;
  setOpenSearch: Dispatch<SetStateAction<boolean>>;
  showInfo: boolean;
  setShowInfo: Dispatch<SetStateAction<boolean>>;
  searchResults: AgentSetting[];
  setSearchResults: Dispatch<SetStateAction<AgentSetting[]>>;
  token: string | null;
};

export default function FilePanelSearch({
  openSearch,
  setOpenSearch,
  showInfo,
  setShowInfo,
  searchResults,
  setSearchResults,
  token,
}: FilePanelSearchProps) {
  const { t } = useTranslation();

  const agentSettings = useAgentSettingsStore((state) => state.agentSettings);

  const { state: isTablet } = useResize(Breakpoints.tablet);

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newResult = agentSettings.filter((item) => item.title.toLowerCase().includes(e.target.value.toLowerCase()));
    setSearchResults(newResult);
  };

  const closeSearchContainer = () => {
    setOpenSearch(false);
    setSearchResults(agentSettings);
  };

  return (
    <aside
      className={`h-full bg-neutral-06 md:border-l md:border-neutral-05 ${
        openSearch ? "w-full md:w-90" : "w-0"
      } 2xl:block`}
    >
      <div className="w-full pl-4 pr-10 py-4 border-b border-pure-white/10">
        <div className="w-full p-4 flex items-center gap-6">
          <Close onClick={closeSearchContainer} className="w-8 h-8 cursor-pointer" />
          <input
            id="search"
            type="text"
            placeholder={t("common.search")}
            onChange={handleSearchChange}
            className="w-full h-10 bg-neutral-06 text-2xl leading-10 font-semibold placeholder:text-neutral-04/80 focus:outline-none focus:caret-electric-purple"
          />
        </div>
      </div>
      <FilePanelSearchList
        searchResults={searchResults}
        showInfo={showInfo}
        closeSearchContainer={closeSearchContainer}
        token={token}
      />
      {showInfo && isTablet && <NewFeaturesAd setShowInfo={setShowInfo} />}
    </aside>
  );
}
