import { useEffect } from "react";
import { createPortal } from "react-dom";
import { useTranslation } from "react-i18next";
import { ReactComponent as Close } from "../../../../assets/svg/x-close.svg";
import { LocalStorageKeys } from "../../../../constants";
import { tutorialSteps } from "../../../../constants/tutorial";
import { useTutorial } from "../../../../stores/tutorialStore";
import Button from "../../Buttons/Button/Button";
import Tagline from "../../Tagline/Tagline";

type TutorialCardMobileProps = {
  skipTutorial: () => void;
  stepBack: () => void;
  nextStep: () => void;
  finishTutorial: () => void;
  exitTutorial: () => void;
};

export default function TutorialCardMobile({
  skipTutorial,
  stepBack,
  nextStep,
  finishTutorial,
  exitTutorial,
}: TutorialCardMobileProps) {
  const { t } = useTranslation();

  const currentStep = useTutorial((state) => state.currentStep);

  useEffect(() => {
    const tutorial = localStorage.getItem(LocalStorageKeys.tutorial);

    tutorial && document.body.classList.add("no-scroll");
  }, []);

  return createPortal(
    <div className="fixed top-0 left-0 w-full h-screen bg-transparent-background z-100">
      <div
        className={`fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[343px] bg-neutral-07 px-4 py-6 rounded-3xl md:w-[416px] md:p-8`}
      >
        {tutorialSteps[currentStep].id !== 0 && tutorialSteps[currentStep].id !== 4 && (
          <div className="absolute top-4 right-4 w-10 h-10 bg-pure-white rounded-full flex justify-center items-center">
            <Close className="w-5 h-5 cursor-pointer stroke-neutral-04" onClick={exitTutorial} />
          </div>
        )}
        <div className="flex gap-2 mb-4 md:flex-col-reverse md:mb-8">
          <h1 className="leading-9 md:text-h5">{t(tutorialSteps[currentStep].title)}</h1>
          <Tagline>0{currentStep + 1}</Tagline>
        </div>
        <p className="text-sm leading-6 text-light-blue-gray mb-4 md:max-w-sm md:text-base md:leading-7 md:mb-10">
          {t(tutorialSteps[currentStep].text)}
        </p>
        <img src="/images/tutorial/tutorial.png" alt="" className="w-[400px] h-[260px]" />
        <div className="text-right mt-8">
          <Button
            px="px-2"
            className={`mr-4 md:mr-6 ${tutorialSteps[currentStep].isBackButton ? "w-30" : "w-36"}`}
            onClick={tutorialSteps[currentStep].isBackButton ? stepBack : skipTutorial}
          >
            {tutorialSteps[currentStep].isBackButton ? t("common.back") : t("common.skip")}
          </Button>
          <Button
            color="white"
            className="w-30"
            onClick={tutorialSteps[currentStep].isNextButton ? nextStep : finishTutorial}
          >
            {tutorialSteps[currentStep].id === 0
              ? t("resetPassword.continueButton")
              : tutorialSteps[currentStep].isNextButton
              ? t("common.next")
              : t("common.finish")}
          </Button>
        </div>
      </div>
    </div>,
    document.getElementById("portal") as HTMLElement
  );
}
