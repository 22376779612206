import { brackets } from "./svgs";

type TaglineProps = {
  className?: string;
  children: React.ReactNode;
};

export default function Tagline({ className, children }: TaglineProps) {
  return (
    <div
      className={`relative z-10 font-space-grotesk text-tag-line tracking-tagline uppercase flex items-center ${
        className || ""
      }`}
    >
      {brackets("left")}
      <div className="mx-3 text-lavender-gray">{children}</div>
      {brackets("right")}
    </div>
  );
}
