import { Dispatch, SetStateAction } from "react";
import NavbarWithMenu from "./NavbarWithMenu";

type NavbarWrapperProps = {
  token: string | null;
  setToken: Dispatch<SetStateAction<string | null>>;
  openMenu: boolean;
  setOpenMenu: Dispatch<SetStateAction<boolean>>;
};

export default function NavbarWrapper({ token, setToken, openMenu, setOpenMenu }: NavbarWrapperProps) {
  return <NavbarWithMenu token={token} setToken={setToken} openMenu={openMenu} setOpenMenu={setOpenMenu} />;
}
