import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { Breakpoints } from "../../../constants";
import { noAuthNavigation } from "../../../constants/navigation";
import useResize from "../../../hooks/useResize";
import { routes } from "../../../routes/routes";
import { Offset } from "../../../types/types";
import { scrollWithOffset } from "../../../utils/scrollWithOffset";
import Button from "../Buttons/Button/Button";
import Logout from "../Buttons/Logout/Logout";
import MenuButton from "../Buttons/MenuButton/MenuButton";
import TranslationDropDown from "../TranslationDropDown/TranslationDropDown";

type NavbarProps = {
  token: string | null;
  setToken: Dispatch<SetStateAction<string | null>>;
  openMenu: boolean;
  setOpenMenu: Dispatch<SetStateAction<boolean>>;
  isInMenu?: boolean;
};

export default function Navbar({ token, setToken, openMenu, setOpenMenu, isInMenu }: NavbarProps) {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const { state: isTablet } = useResize(Breakpoints.tablet);
  const { state: isLaptop } = useResize(Breakpoints.laptop);
  const { state: isDesktop } = useResize(Breakpoints.desktop);

  const setOffset = (offset: Offset) => {
    if (isDesktop) {
      return offset.desktop;
    } else if (isLaptop) {
      return offset.laptop;
    } else if (isTablet) {
      return offset.tablet;
    } else {
      return offset.mobile;
    }
  };

  const location = useLocation();

  return (
    <nav
      className={`${
        isInMenu ? "absolute top-0 left-0 border-b border-pale-lavender/10" : ""
      } w-full h-19 bg-midnight-blue flex justify-between items-center px-4 xl:px-10`}
    >
      <Link to={routes.home} className="relative w-[330px] z-1 flex items-center gap-2">
        <img src="/JBLogo.png" alt="JellyBean Logo" />
        <h2 className="relative text-28 font-bold leading-7">
          JellyBean <span className="absolute text-xs font-light">&trade;</span>
        </h2>
      </Link>
      {isLaptop && location.pathname === routes.home && (
        <ul className="flex z-50">
          {noAuthNavigation.map(
            (item) =>
              item.displayInRow && (
                <li
                  key={item.id}
                  className="font-source-code-pro text-xs text-pure-white/50 font-semibold leading-5 uppercase lg:px-4 xl:px-8 2xl:px-12"
                >
                  <HashLink smooth to={item.route} scroll={(el) => scrollWithOffset(el, setOffset(item.offset))}>
                    {t(item.title)}
                  </HashLink>
                </li>
              )
          )}
        </ul>
      )}
      <div className="relative z-50 flex justify-end items-center gap-8">
        {isLaptop ? (
          <>
            <TranslationDropDown />
            <button
              onClick={token ? () => navigate(routes.myAccount) : () => navigate(routes.signup)}
              className="font-source-code-pro text-button text-pure-white/50 uppercase tracking-wider"
            >
              {token ? t("myAccount") : t("newAccount")}
            </button>
            {token ? (
              <Logout title={t("common.logout")} setToken={setToken} className="w-30" />
            ) : (
              <Button onClick={() => navigate(routes.login)} className="w-30">
                {t("loginButton")}
              </Button>
            )}
          </>
        ) : (
          <div className="relative z-1">
            {openMenu ? (
              <MenuButton onClick={() => setOpenMenu(false)} px="px-3" icon="CLOSE" />
            ) : (
              <MenuButton onClick={() => setOpenMenu(true)} px="px-3" icon="OPEN" />
            )}
          </div>
        )}
      </div>
    </nav>
  );
}
