import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { routes } from "../../../routes/routes";
import { useAgentSettingsStore } from "../../../stores/agentSettingsStore";
import { useGlobalStore } from "../../../stores/globalStore";
import { AgentSetting } from "../../../types/types";
import FilePanelContainer from "./Components/FilePanelContainer";
import FilePanelSearch from "./Components/FilePanelSearch";
import FilePanelTutorial from "./Components/FilePanelTutorial";

type FilePanelOpenProps = {
  token: string | null;
};

export default function FilePanelOpen({ token }: FilePanelOpenProps) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [searchResults, setSearchResults] = useState<AgentSetting[]>([]);
  const [openSearch, setOpenSearch] = useState(false);
  const [showInfo, setShowInfo] = useState(false);

  const location = useLocation();

  const agentSettings = useAgentSettingsStore((state) => state.agentSettings);
  const setAgentSettings = useAgentSettingsStore((state) => state.setAgentSettings);
  const isTutorialPanelOpen = useGlobalStore((state) => state.isTutorialPanelOpen);

  useEffect(() => {
    location.pathname === routes.dashboard && setShowInfo(true);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(`${process.env.REACT_APP_BASE_API_URL}/api/agent-settings/get-all`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          setAgentSettings(data);
          setIsLoading(false);
        } else {
          console.error("Failed to fetch data");
          setIsLoading(false);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsLoading(false);
      }
    };

    if (agentSettings.length === 0) {
      fetchData();
    }
  }, []);

  return (
    <>
      {isTutorialPanelOpen ? (
        <FilePanelTutorial />
      ) : !openSearch ? (
        <FilePanelContainer
          isLoading={isLoading}
          showInfo={showInfo}
          setShowInfo={setShowInfo}
          setOpenSearch={setOpenSearch}
          setSearchResults={setSearchResults}
          token={token}
        />
      ) : (
        <FilePanelSearch
          openSearch={openSearch}
          setOpenSearch={setOpenSearch}
          showInfo={showInfo}
          setShowInfo={setShowInfo}
          searchResults={searchResults}
          setSearchResults={setSearchResults}
          token={token}
        />
      )}
    </>
  );
}
