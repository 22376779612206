import { createPortal } from "react-dom";
import { useTranslation } from "react-i18next";
import { ReactComponent as Close } from "../../../../assets/svg/x-close.svg";
import { tutorialSteps } from "../../../../constants/tutorial";
import { useTutorial } from "../../../../stores/tutorialStore";
import Button from "../../Buttons/Button/Button";
import Tagline from "../../Tagline/Tagline";

type TutorialCardProps = {
  skipTutorial: () => void;
  stepBack: () => void;
  nextStep: () => void;
  finishTutorial: () => void;
  exitTutorial: () => void;
};

export default function TutorialCard({
  skipTutorial,
  stepBack,
  nextStep,
  finishTutorial,
  exitTutorial,
}: TutorialCardProps) {
  const { t } = useTranslation();

  const currentStep = useTutorial((state) => state.currentStep);
  return createPortal(
    <div className="fixed top-0 left-0 w-full h-screen bg-transparent-background z-100">
      <div
        className={`fixed ${tutorialSteps[currentStep].position} w-[416px] bg-neutral-07 p-8 rounded-3xl lg:w-[496px] lg:p-12`}
      >
        {tutorialSteps[currentStep].id !== 0 && tutorialSteps[currentStep].id !== 4 && (
          <Close className="absolute top-8 right-8 w-8 h-8 cursor-pointer" onClick={exitTutorial} />
        )}
        <div className="flex gap-2 mb-8 flex-col-reverse lg:flex-row lg:items-center">
          <h1 className="text-h5 leading-9">{t(tutorialSteps[currentStep].title)}</h1>
          <Tagline>0{currentStep + 1}</Tagline>
        </div>
        <p className="max-w-sm leading-7 text-light-blue-gray mb-10">{t(tutorialSteps[currentStep].text)}</p>
        <img src="/images/tutorial/tutorial.png" alt="" className="w-[400px] h-[260px]" />
        <div className="text-right mt-8">
          <Button
            px="px-2"
            className={`mr-6 ${tutorialSteps[currentStep].isBackButton ? "w-30" : "w-36"}`}
            onClick={tutorialSteps[currentStep].isBackButton ? stepBack : skipTutorial}
          >
            {tutorialSteps[currentStep].isBackButton ? t("common.back") : t("common.skip")}
          </Button>
          <Button
            color="white"
            className="w-30"
            onClick={tutorialSteps[currentStep].isNextButton ? nextStep : finishTutorial}
          >
            {tutorialSteps[currentStep].id === 0
              ? t("resetPassword.continueButton")
              : tutorialSteps[currentStep].isNextButton
              ? t("common.next")
              : t("common.finish")}
          </Button>
        </div>
      </div>
    </div>,
    document.getElementById("portal") as HTMLElement
  );
}
