import { mountStoreDevtool } from "simple-zustand-devtools";
import { create } from "zustand";

type GlobalStore = {
  isSidebarOpen: boolean;
  openSidebar: () => void;
  closeSidebar: () => void;
  isFilePanelOpen: boolean;
  openFilePanel: () => void;
  closeFilePanel: () => void;
  isTutorialPanelOpen: boolean;
  openTutorialPanel: () => void;
  closeTutorialPanel: () => void;
  isSettingsOpen: boolean;
  openSettings: () => void;
  closeSettings: () => void;
};

export const useGlobalStore = create<GlobalStore>()((set) => ({
  isSidebarOpen: false,
  openSidebar: () => set(() => ({ isSidebarOpen: true })),
  closeSidebar: () => set(() => ({ isSidebarOpen: false })),
  isFilePanelOpen: false,
  openFilePanel: () => set(() => ({ isFilePanelOpen: true })),
  closeFilePanel: () => set(() => ({ isFilePanelOpen: false })),
  isTutorialPanelOpen: false,
  openTutorialPanel: () => set(() => ({ isTutorialPanelOpen: true })),
  closeTutorialPanel: () => set(() => ({ isTutorialPanelOpen: false })),
  isSettingsOpen: false,
  openSettings: () => set(() => ({ isSettingsOpen: true })),
  closeSettings: () => set(() => ({ isSettingsOpen: false })),
}));

if (process.env.NODE_ENV === "development") {
  mountStoreDevtool("GlobalStore", useGlobalStore);
}
