import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as Collapse } from "../../../../assets/svg/collapse.svg";
import { ReactComponent as Search } from "../../../../assets/svg/search.svg";
import { useAgentSettingsStore } from "../../../../stores/agentSettingsStore";
import { useGlobalStore } from "../../../../stores/globalStore";
import { AgentSetting } from "../../../../types/types";

type FilePanelHeaderProps = {
  setOpenSearch: Dispatch<SetStateAction<boolean>>;
  setSearchResults: Dispatch<SetStateAction<AgentSetting[]>>;
};

export default function FilePanelHeader({ setOpenSearch, setSearchResults }: FilePanelHeaderProps) {
  const { t } = useTranslation();

  const agentSettings = useAgentSettingsStore((state) => state.agentSettings);
  const closeFilePanel = useGlobalStore((state) => state.closeFilePanel);

  return (
    <div className="w-full h-[88px] border-b border-neutral-05 px-13 flex justify-between items-center md:px-10">
      <div className="flex gap-3 lg:gap-2">
        <div className="flex items-center">
          <button className="hidden mr-8 md:block" onClick={closeFilePanel}>
            <Collapse />
          </button>
          <p className="text-light-blue-gray text-caption mr-2 md:font-semibold">
            {t("dashboardMobile.chatHistory.title")}
          </p>
          <p className="bg-[#3a4042]/50 text-neutral-04 text-xs leading-5 font-medium px-2 rounded-lg flex justify-center items-center lg:text-sm lg:font-medium lg:leading-5">
            {t("dashboardMobile.chatHistory.numberOfData", { number: agentSettings.length })}
          </p>
        </div>
      </div>
      <Search
        onClick={() => {
          setOpenSearch(true);
          setSearchResults(agentSettings);
        }}
        className="cursor-pointer"
      />
    </div>
  );
}
