import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as Check } from "../../../assets/svg/check-02.svg";
import { ReactComponent as DownArrow } from "../../../assets/svg/chevron-down.svg";
import { ReactComponent as Globe } from "../../../assets/svg/globe.svg";
import { LocalStorageKeys } from "../../../constants";
import { translation } from "../../../constants/translation";
import { useCookieConsent } from "../../../stores/cookieConsentStore";
import { useCurrentLanguage } from "../../../stores/currentLanguageStore";

export default function TranslationDropDown() {
  const { i18n, t } = useTranslation();

  const [openTranslationMenu, setOpenTranslationMenu] = useState(false);

  const language = useCurrentLanguage((state) => state.language);
  const changeLanguage = useCurrentLanguage((state) => state.changeLanguage);
  const isCookieConsent = useCookieConsent((state) => state.cookieConsent);

  const changeGlobalLanguage = (newLanguage: string) => {
    changeLanguage(newLanguage);
    setOpenTranslationMenu(false);
    i18n.changeLanguage(newLanguage);
    if (isCookieConsent === "true") localStorage.setItem(LocalStorageKeys.i18nextLng, newLanguage);
  };

  const changeLanguageAndCloseMenu = (shortcut: string) => {
    changeGlobalLanguage(shortcut);
    setOpenTranslationMenu((prev) => !prev);
  };

  return (
    <div
      className="hidden relative gap-2 cursor-pointer lg:flex"
      onClick={() => setOpenTranslationMenu((prev) => !prev)}
    >
      <div className="flex">
        <Globe className="stroke-pure-white/50" />
      </div>
      <button>
        <DownArrow className={`stroke-pure-white/50 ${openTranslationMenu && "rotate-180"}`} />
      </button>
      {openTranslationMenu && (
        <ul className="absolute -bottom-60 -left-36 w-52 bg-neutral-06 rounded-xl py-4">
          {translation.map((item) => (
            <li
              key={item.id}
              onClick={() => changeLanguageAndCloseMenu(item.shortcut)}
              className="w-full h-11 flex justify-between items-center px-8 hover:bg-light-blue"
            >
              <p>{t(item.language)}</p>
              {item.shortcut === language && <Check />}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}
