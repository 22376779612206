import { ReactComponent as Help } from "../assets/svg/help.svg";
import { ReactComponent as Database } from "../assets/svg/sidebar-menu/database-02.svg";
import { ReactComponent as Chat } from "../assets/svg/sidebar-menu/message-chat-square.svg";
import { ReactComponent as Puzzle } from "../assets/svg/sidebar-menu/puzzle-piece.svg";
import { ReactComponent as User } from "../assets/svg/user-category.svg";
import { routes } from "../routes/routes";

export const categoriesOptions = {
  myAccount: "categoryList.myAccount",
  tutorialGuide: "categoryList.tutorialGuide",
  dataUpload: "categoryList.uploadData",
  integrate: "categoryList.integrate",
  chat: "categoryList.chat",
  settings: "categoryList.settings",
};

export const categories = [
  {
    id: 0,
    title: categoriesOptions.myAccount,
    route: routes.myAccount,
    backgroundColor: "bg-error-red/20",
    icon: User,
    iconColor: "stroke-error-red",
  },
  {
    id: 1,
    title: categoriesOptions.tutorialGuide,
    route: routes.dashboard,
    backgroundColor: "bg-sunset-orange/20",
    icon: Help,
    iconColor: "",
  },
  {
    id: 2,
    title: categoriesOptions.dataUpload,
    route: routes.dataUpload,
    backgroundColor: "bg-primary-02/20",
    icon: Database,
    iconColor: "stroke-primary-02",
  },
  {
    id: 3,
    title: categoriesOptions.integrate,
    route: routes.integration,
    backgroundColor: "bg-primary/20",
    icon: Puzzle,
    iconColor: "stroke-primary",
  },
  {
    id: 4,
    title: categoriesOptions.chat,
    route: routes.playground,
    backgroundColor: "bg-accents-03/20",
    icon: Chat,
    iconColor: "stroke-accents-03",
  },
];
