import { ReactComponent as Collapse } from "../../../assets/svg/collapse-02.svg";
import { useGlobalStore } from "../../../stores/globalStore";

export default function FilePanelClose() {
  const openFilePanel = useGlobalStore((state) => state.openFilePanel);

  return (
    <aside className="w-24 bg-neutral-06 border-l border-neutral-05  2xl:block">
      <div className="w-full h-18 border-b border-neutral-05 flex justify-center items-center">
        <button onClick={openFilePanel} className="rotate-180">
          <Collapse />
        </button>
      </div>
    </aside>
  );
}
