import jwt_decode from "jwt-decode";
import { lazy, Suspense, useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { EMAILKEY, LocalStorageKeys, NAMEIDENTIFIERKEY } from "./constants";
import { routes } from "./routes/routes";

import AuthorizationWrapper from "./layouts/AuthorizationWrapper";
import NavbarWrapper from "./layouts/NavbarWrapper";

import { useTranslation } from "react-i18next";
import Loading from "./components/Elements/Loading/Loading";
import Notification from "./components/Elements/Notification/Notification";
import ConfirmEmail from "./components/Pages/SignUp/ConfirmEmail";
import { useCurrentLanguage } from "./stores/currentLanguageStore";
import { useUserStore } from "./stores/userStore";
import { DecodeType } from "./types/types";

const Landing = lazy(() => import("./components/Pages/Landing/Landing"));
const Features = lazy(() => import("./components/Pages/Landing/Features/Features"));
const Pricing = lazy(() => import("./components/Pages/Landing/Pricing/Pricing"));
const HowToUse = lazy(() => import("./components/Pages/Landing/HowToUse/HowToUse"));
const Roadmap = lazy(() => import("./components/Pages/Landing/Roadmap/Roadmap"));
const Dashboard = lazy(() => import("./components/Pages/Dashboard/Dashboard"));
const DataUpload = lazy(() => import("./components/Pages/DataUpload/DataUpload"));
const Integration = lazy(() => import("./components/Pages/Integration/Integration"));
const IntegrationSteps = lazy(() => import("./components/Pages/Integration/IntegrationSteps"));
const Playground = lazy(() => import("./components/Pages/Playground/Playground"));
const Translation = lazy(() => import("./components/Pages/Translation/Translation"));
const Login = lazy(() => import("./components/Pages/Login/Login"));
const SignUp = lazy(() => import("./components/Pages/SignUp/SignUp"));
const ForgotPassword = lazy(() => import("./components/Pages/ForgotPassword/ForgotPassword"));
const ResetPassword = lazy(() => import("./components/Pages/ForgotPassword/ResetPassword"));
const SubPricing = lazy(() => import("./components/Pages/SubPricing/SubPricing"));
const SubRoadmap = lazy(() => import("./components/Pages/SubRoadmap/SubRoadmap"));
const Policy = lazy(() => import("./components/Pages/Policy/Policy"));
const Terms = lazy(() => import("./components/Pages/Terms/Terms"));
const MyAccount = lazy(() => import("./components/Pages/MyAccount/MyAccount"));
const NotFound = lazy(() => import("./components/Pages/NotFound/NotFound"));

export default function App() {
  const { i18n } = useTranslation();

  const [token, setToken] = useState<string | null>(null);
  const [openMenu, setOpenMenu] = useState(false);

  const changeLanguage = useCurrentLanguage((state) => state.changeLanguage);

  const setUser = useUserStore((state) => state.setUser);

  useEffect(() => {
    const cookieConsent = localStorage.getItem(LocalStorageKeys.cookieConsent);
    const token = localStorage.getItem(LocalStorageKeys.token);

    if (cookieConsent === "false") {
      localStorage.removeItem(LocalStorageKeys.cookieConsent);
      localStorage.removeItem(LocalStorageKeys.token);
    } else {
      if (token) {
        setToken(token);
        const decoded: DecodeType = jwt_decode(token);
        const id = decoded[NAMEIDENTIFIERKEY];
        const username = decoded.username;
        const email = decoded[EMAILKEY];
        setUser(id, username, email);
      }
    }

    const currentLanguage = localStorage.getItem(LocalStorageKeys.i18nextLng);

    if (currentLanguage !== null) {
      changeLanguage(currentLanguage);
    } else {
      changeLanguage(i18n.language);
    }
  }, []);

  return (
    <BrowserRouter>
      <Notification />
      <Suspense fallback={<Loading />}>
        <Routes>
          <Route
            path="/"
            element={<NavbarWrapper token={token} setToken={setToken} openMenu={openMenu} setOpenMenu={setOpenMenu} />}
          >
            <Route path={routes.login} element={<Login token={token} setToken={setToken} />} />
            <Route path={routes.signup} element={<SignUp />} />
            <Route path={routes.home} element={<Landing />} />
            <Route path={routes.translation} element={<Translation />} />
            <Route path={routes.forgotPassword} element={<ForgotPassword />} />
            <Route path={routes.resetPassword} element={<ResetPassword />} />
            <Route path={routes.subPricing} element={<SubPricing />} />
            <Route path={routes.subRoadmap} element={<SubRoadmap />} />
            <Route path={routes.policy} element={<Policy />} />
            <Route path={routes.terms} element={<Terms />} />
            <Route path={routes.notFount} element={<NotFound />} />
          </Route>

          <Route path={routes.confirmEmail} element={<ConfirmEmail />} />
          <Route path={routes.features} element={<Features />} />
          <Route path={routes.pricing} element={<Pricing />} />
          <Route path={routes.howToUse} element={<HowToUse />} />
          <Route path={routes.roadmap} element={<Roadmap />} />

          <Route
            path="/"
            element={
              <AuthorizationWrapper token={token} setToken={setToken} openMenu={openMenu} setOpenMenu={setOpenMenu} />
            }
          >
            <Route path={routes.dashboard} element={<Dashboard />} />
            <Route path={routes.dataUpload} element={<DataUpload />} />
            <Route path={routes.integration} element={<Integration />} />
            <Route path={routes.integrationSteps} element={<IntegrationSteps />} />
            <Route path={routes.playground} element={<Playground />} />
            <Route path={routes.myAccount} element={<MyAccount />} />
          </Route>
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}
