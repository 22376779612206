export const routes = {
  home: "/",
  login: "/login",
  signup: "/signup",
  forgotPassword: "/forgot-password",
  resetPassword: "/reset-password",
  confirmEmail: "/confirm-email",
  dashboard: "/dashboard",
  dataUpload: "/data-upload",
  integration: "/integration",
  integrationSteps: "/integration-steps",
  playground: "/playground",
  settings: "/settings",
  features: "/#features",
  pricing: "/#pricing",
  howToUse: "/#how-to-use",
  roadmap: "/#roadmap",
  translation: "/translation",
  subPricing: "/pricing",
  subRoadmap: "/roadmap",
  policy: "/privacy-policy",
  terms: "/terms-of-use",
  myAccount: "/my-account",
  notFount: "*",
};
