import ContentLoader from "react-content-loader";

const FadingLoader = ({ number }: { number: number }) => {
  return (
    <div className="mt-4 mb-2 flex flex-col gap-8">
      {Array.from({ length: number }).map((_, index) => (
        <FadingLoaderCard key={index} />
      ))}
    </div>
  );
};

const FadingLoaderCard = () => {
  return (
    <ContentLoader width={"30rem"} height={"4rem"} backgroundColor="#212728" foregroundColor="#646464">
      <rect x="60" y="0" rx="6" ry="6" width="200" height="24" />
      <rect x="60" y="30" rx="6" ry="6" width="250" height="24" />
      <rect x="20" y="5" rx="8" ry="8" width="24" height="24" />
    </ContentLoader>
  );
};

export default FadingLoader;
