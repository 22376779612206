export const translation = [
  {
    id: 0,
    language: `translation.languages.zh`,
    shortcut: "zh",
  },
  {
    id: 1,
    language: `translation.languages.en`,
    shortcut: "en",
  },
  {
    id: 2,
    language: `translation.languages.pt`,
    shortcut: "pt",
  },
  {
    id: 3,
    language: `translation.languages.sv`,
    shortcut: "sv",
  },
];
