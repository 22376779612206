import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { routes } from "../../../../routes/routes";

type NewFeaturesAdProps = {
  setShowInfo: Dispatch<SetStateAction<boolean>>;
};

export default function NewFeaturesAd({ setShowInfo }: NewFeaturesAdProps) {
  const { t } = useTranslation();

  return (
    <div className="w-full px-4 pb-8 md:py-8">
      <img src="/images/dashboard/new-feature.png" alt="" className="mx-auto mb-8" />
      <p className="text-base leading-6 font-medium text-neutral-04 mb-8">
        {t("dashboardMobile.chatHistory.infoText")}
      </p>
      <div className="text-end">
        <button onClick={() => setShowInfo(false)} className="text-sm leading-6 font-medium text-grey-chat mr-6">
          {t("dashboardMobile.chatHistory.infoDismiss")}
        </button>
        <Link to={routes.integration} className="text-sm leading-6 font-medium text-electric-purple">
          {t("dashboardMobile.chatHistory.infoNew")}
        </Link>
      </div>
    </div>
  );
}
