import { mountStoreDevtool } from "simple-zustand-devtools";
import { create } from "zustand";

type CurrentLanguage = {
  language: string;
  changeLanguage: (language: string) => void;
};

export const useCurrentLanguage = create<CurrentLanguage>()((set) => ({
  language: "",
  changeLanguage: (language) => set((state) => ({ language })),
}));

if (process.env.NODE_ENV === "development") {
  mountStoreDevtool("CurrentLanguageStore", useCurrentLanguage);
}
