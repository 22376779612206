import { routes } from "../routes/routes";

export const EMAILVALIDATION = /\S+@\S+\.\S+/;
export const PASSWORDVALIDATION =
  /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*_-])(?=.{6,})[a-zA-Z0-9!@#$%^&*_-]+$/;

export const EMAIL: string = "Email";

export const LocalStorageKeys = {
  cookieConsent: "cookieConsent",
  token: "token",
  i18nextLng: "i18nextLng",
  tutorial: "tutorial",
  tutorialCurrentStep: "tutorialCurrentStep",
};

export const AI: string = "AI";

export const USER: string = "User";

export const ERROR: string = "error";

export const Breakpoints = {
  largeMobile: 640,
  tablet: 768,
  laptop: 1024,
  desktop: 1280,
  largeDesktop: 1536,
};

export const URL_AGENT_SETTINGS = `${process.env.REACT_APP_BASE_API_URL}/api/agent-settings`;

export const EMAILKEY = "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress";
export const NAMEIDENTIFIERKEY = "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier";

export const UPDATEACCOUNT = "Update account";
export const CHANGEPASSWORD = "Change password";

export const DashboardSubPagesNumber = {
  dashboard: 0,
  dataUpload: 1,
  integration: 2,
  playground: 3,
};

export const dashboardRoutes = [
  routes.dashboard,
  routes.dataUpload,
  routes.integration,
  routes.integrationSteps,
  routes.playground,
  routes.settings,
  routes.myAccount,
];
